import React from 'react';
import './Footer.css'; // Create this CSS file for styling
import {FaFacebook, FaInstagram, FaTwitter} from 'react-icons/fa';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Contact Info */}
        <div className="contact-info">
          <p>Email: info@savannahsafaris.com</p>
          <p>Phone: +254792949157</p>
          <p>Phone: +254729143740</p>
        </div>
        
        {/* Social Media Icons */}
        <div className="social-icons">
          <a href="https://www.facebook.com/profile.php?id=61566452255131" target="_blank" rel="noopener noreferrer">
          {/* <i className="fab fa-facebook-f"></i> */}
          <FaFacebook className="icons facebook"/>
          </a>
          <a href="https://www.instagram.com/savanah_safaris/profilecard/?igsh=ZjNiZHlrY2lnNXp6" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="icons instagram"/>
          </a>
          {/* <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter className="icons twitter"/>
          </a> */}
        </div>

        {/* Copyright Info */}
        <div className="footer-copyright">
          <p>© 2024 Savannah Safaris. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
