import React from 'react';
import Banner from './Banner'; // Import the reusable Banner component
import TextImage from './TextImage';
import './AboutUs.css';

function AboutUs() {
  return (
    <div>
     
      <Banner image="/images/hiking.jpg" title="About Us" />
        <div style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "10vh" }}>
            <h1>
                Who We Are
            </h1>
            <p>
            We make travel exciting by offering comprehensive travel solutions. Partnering with top flight, hotel, tour, and insurance providers, we bring all your travel needs together in one place, ensuring a seamless and enjoyable journey from start to finish
            </p>
        </div>
      <TextImage 
      heading="Our Mission"
      text="Our mission is to make travel and exploration seamless for everyone by providing all-inclusive travel solutions. Through partnerships with top providers for flights, hotels, and tours, we aim to deliver exceptional experiences that cater to every traveler’s needs, ensuring a journey that is both smooth and unforgettable."
      imageSrc="/images/hike.jpg"
      imageAlt="Snow "
      />
 <div className="core-values-section">
            <h2 className="heading">Our Core Values</h2>
            <p className="intro">
                At Savannah Safaris, we are client-centered, committed to making travel as seamless and comfortable as possible. Your satisfaction is our priority, and we strive to deliver personalized, hassle-free travel experiences every step of the way.
            </p>

            <div className="core-values-list">
                <div className="core-value">
                    <h3 className="value-heading">Client-Centered Service</h3>
                    <p className="value-description">
                        We prioritize your needs and comfort, ensuring personalized and seamless travel experiences.
                    </p>
                </div>

                <div className="core-value">
                    <h3 className="value-heading">Excellence in Partnership</h3>
                    <p className="value-description">
                        By working with the best providers, we offer top-quality flights, hotels, and tours.
                    </p>
                </div>


                <div className="core-value">
                    <h3 className="value-heading">Integrity and Trust</h3>
                    <p className="value-description">
                    We believe in transparency, reliability, and delivering on our promises for a worry-free journey.
                    </p>
                </div>
            </div>
        </div>

    </div>
  );
}

export default AboutUs;
