import React from 'react';
import './Banner.css'; // This will contain the styles for the banner

function Banner({ image, title }) {
  return (
    <div 
      className="banner" 
      style={{ backgroundImage: `url(${image})` }} // Dynamic image
    >
      <div className="banner-content">
        <h1 className="banner-title">{title}</h1>
      </div>
    </div>
  );
}

export default Banner;
