import React from 'react';
import './Gallery.css'; // Create this file for the gallery styling

function Gallery() {
  return (
    <div className="gallery-section">
      <h2 className="gallery-title">Possible Tours</h2>
      <div className="gallery-grid">
        <img src="/images/camp.jpg" alt="Tour 1" className="gallery-item big" />
        <img src="/images/hiking.jpg" alt="Tour 2" className="gallery-item" />
        <img src="/images/ocean.avif" alt="Tour 3" className="gallery-item" />
        <img src="/images/fall.jpg" alt="Tour 4" className="gallery-item big" />
        <img src="/images/outdoor.jpg" alt="Tour 5" className="gallery-item" />
        <img src="/images/desert.avif" alt="Tour 6" className="gallery-item" />
      </div>
      <ul>
        <li>Hiking</li>
        <li>Desert Safaris</li>
        <li>Ocean Surfing</li>
        <li>Camping</li>
        <li>Hot Air Ballooning</li>
        <li>...</li>
      </ul>
    </div>
  );
}

export default Gallery;
