import React from 'react';
import BannerSlider from './BannerSlider';
import QuoteSection from './QuoteSection';
import CardSection from './CardSection';
import TextImage from './TextImage';
import Gallery from './Gallery';

function Home() {
  return (
    <div>
      {/* <Navbar /> */}
      <BannerSlider />
      <QuoteSection />
      <CardSection />
      <TextImage 
      heading="WHERE THE ADVENTURE NEVER ENDS"
      text="Explore the roads less travelled, where your passion for outdoors  adventure can roam wild and your appreciation for nature deepens. Ranging from the spectacular moutains, and oceans to extensive desearts and national parks in Africa and beyond."
      imageSrc="/images/snow.jpg"
      imageAlt="Snow "
      />
      <Gallery />

    </div>
  );
}

export default Home;
