import React from 'react';
import './QuoteSection.css'; // You'll create this file for styling

function QuoteSection() {
  return (
    <div className="quote-section">
      <img src="/images/quote.png" alt="Quote Icon" className="quote-icon" />
      <div className="quote-text">
        <p>
          "If we were ment to stay in one place, we would have roots instead of feet"
        </p>
        <p className="author">
          - Rachel Wolchin
        </p>
      </div>
    </div>
  );
}

export default QuoteSection;
