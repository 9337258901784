import React from 'react';
import './TextImage.css';

function TextImage({ heading, text, imageSrc, imageAlt }) {
  return (
    <div className="text-image-row">
      <div className="text-content">
        <h2>{heading}</h2>
        <p>{text}</p>
      </div>
      <div className="image-content">
        <img src={imageSrc} alt={imageAlt} />
      </div>
    </div>
  );
}

export default TextImage;

