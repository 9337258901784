import React from 'react';
import Banner from './Banner'; // Assuming you have a Banner component for the page's banner
import './Services.css'; // Create this file for custom styling

function ServicesPage() {
  return (
    <div>
      <Banner image="/images/ocean.avif" title="Our Services" />

      <div className="services-intro">
        <h1>Explore Our Services</h1>
        <p>We have partnered with outstanding flight and hotel providers to offer you a seamless travel experience. Contact us via phone or email, and we'll take care of the details for you!</p>
      </div>

      <div className="services-grid">
        <div className="service-card">
          <img src="/images/flight.jpg" alt="Flights" className="service-image" />
          <h3>Flight Bookings</h3>
          <p>We help you book flights with the best airlines, ensuring you travel comfortably and affordably to your destination.</p>
        </div>

        <div className="service-card">
          <img src="/images/stays.jpg" alt="Hotels/Stays" className="service-image" />
          <h3>Hotel & Stays</h3>
          <p>We partner with top hotels to provide luxurious and budget-friendly accommodations, hand-picked just for you.</p>
        </div>

        <div className="service-card">
          <img src="/images/tour.jpg" alt="Tours" className="service-image" />
          <h3>Tours & Excursions</h3>
          <p>We organize amazing tours around the world, offering you unforgettable experiences with top-rated tour guides.</p>
        </div>

        <div className="service-card">
          <img src="/images/van.jpeg" alt="Transfers" className="service-image" />
          <h3>Hotel Transfers</h3>
          <p>We provide safe and reliable hotel, airport, and city transfers to ensure your journey is stress-free and smooth.</p>
        </div>
      </div>

      <div className="contact-section">
        <p>To book any of our services, reach out via phone or email, or fill out our <a href="/contact-us">Contact Form</a> and we’ll get in touch with you.</p>
        <p>Phone: +123 456 7890 | Email: info@savannahsafaris.com</p>
      </div>
    </div>
  );
}

export default ServicesPage;
