import React from 'react';
import Slider from 'react-slick';
import './BannerSlider.css'; 
function BannerSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="banner-slider">
      <Slider {...settings}>
        <div className="slide">
          <img src="/images/flight.jpg" alt="Banner 1"  />
          <div className="slide-text">
            <h1>Flight Bookings</h1>
            <p>We've partnered with top travel providers for best flight deals.</p>
          </div>
        </div>
        <div className="slide">
          <img src="/images/giraffe.jpg" alt="Slide 2" />
          <div className="slide-text">
            <h1>Hotel Bookings</h1>
            <p>Find your perfect Stay. <br/>- We have partnered with the best hotels providers for your altmost expereince</p>
          </div>
        </div>
        <div className="slide">
          <img src="/images/hotel.jpg" alt="Slide 3" />
          <div className="slide-text">
            <h1>Tours Bookings</h1>
            <p>Book your dream tour packages with us for unforgetable adventure.</p>
          </div>
        </div>
        <div className="slide">
          <img src="/images/hotel.jpg" alt="Slide 3" />
          <div className="slide-text">
            <h1>Transfer Services</h1>
            <p>We offer the best transfer service putting customer comfort at the forefront.</p>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default BannerSlider;
