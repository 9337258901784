import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home'; // Your Home page
import AboutUs from './components/AboutUs'; // Your About Us page
import Services from './components/Services'; // Your Services page
import ContactUs from './components/ContactUs'; // Your Contact Us page
import Navbar from './components/Navbar'; // Import Header
import Footer from './components/Footer'; // Import Footer


function App() {
  return (
    <Router>
      <div className="App">
        <Navbar /> {/* Include Header here */}
        <Routes>
          <Route path="/" element={<Home />} /> {/* Home Page */}
          <Route path="/about" element={<AboutUs />} /> {/* About Us Page */}
          <Route path="/services" element={<Services />} /> {/* Services Page */}
          <Route path="/contact" element={<ContactUs />} /> {/* Contact Us Page */}
        </Routes>
        <Footer /> {/* Include Footer here */}
      </div>
    </Router>
  );
}

export default App;
