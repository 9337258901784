import React, { useState } from 'react';
import Banner from './Banner';
import './ContactUs.css';

function ContactUs() {
  // State to handle form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    serviceType: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic for handling form submission can go here
    console.log('Form Submitted', formData);
    alert('Reservation form submitted successfully!');
  };

  return (
    <div>
      <Banner image="/images/hiking.jpg" title="Contact Us" />

      <div className="contact-intro">
        <h1>Book with Us</h1>
        <p>For hotel, flights, tours, transfers, or any travel service reservation, fill and submit the form below.</p>
      </div>

      <form className="reservation-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Full Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Your Full Name"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email Address:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Your Email"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone Number:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Your Phone Number"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="serviceType">Service Type:</label>
          <select
            id="serviceType"
            name="serviceType"
            value={formData.serviceType}
            onChange={handleChange}
            required
          >
            <option value="">Select a Service</option>
            <option value="hotel">Hotel Booking</option>
            <option value="flights">Flights Booking</option>
            <option value="tours">Tours Booking</option>
            <option value="transfers">Transfers (Hotel/Airport)</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="message">Additional Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Enter any specific requests or questions"
            rows="5"
          ></textarea>
        </div>

        <button type="submit" className="submit-btn">Submit Reservation</button>
      </form>
    </div>
  );
}

export default ContactUs;
