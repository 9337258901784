import React from 'react';
import './CardSection.css'; // You'll create this file for styling

function CardSection() {
  return (
    <div className="card-section">
      <div className="card">
        <img src="/images/flight.jpg" alt="Card 1" className="card-image" />
        <div className="card-content">
          <h3>Flights</h3>
          <p>Wanna travel to your destination via flight and dont know where to start, Savanah Safaris got you.
        <br></br> Book your flight of choice through us.
          </p>
          <button className="learn-more-button">Learn More</button>
        </div>
      </div>

      <div className="card">
        <img src="/images/umbrella.jpg" alt="Card 2" className="card-image" />
        <div className="card-content">
          <h3>Stays</h3>
          <p>We will help you book your dream hotels across the world. <br></br>
          We select and book the best stay for our clients.</p>
          <button className="learn-more-button">Learn More</button>
        </div>
      </div>

      <div className="card">
        <img src="/images/tour.jpg" alt="Card 3" className="card-image" />
        <div className="card-content">
          <h3>Tours</h3>
          <p>Explore the world with us! <br></br> We have amaizing travel packages around the world. Choose and we organise you get there.</p>
          <button className="learn-more-button">Learn More</button>
        </div>
      </div>
      <div className="card">
        <img src="/images/van.jpeg" alt="Card 3" className="card-image" />
        <div className="card-content">
          <h3>Transfers</h3>
          <p>Your safety is guaranteed.Savanah Safaris offers comfortable & safe hotel, airport, sgr etc.. transfer services.</p>
          <button className="learn-more-button">Learn More</button>
        </div>
      </div>
    </div>
  );
}

export default CardSection;
